@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

$primary: #90a4ae;
$primaryDark: #62757f;
$primarySuperDark: #62757f;
$primaryLight: #c1d5e0;
$secondary: #4dd0e1;
$secondaryLight: #88ffff;
$secondarySuperLight: #d1ffff;
$danger: #ef5350;
$warningLight:#ffff85;

$success: #69c07b;
$warning: #ecef52;

$theme-colors: (
  "dangerLight": #f6a3a2,
  "successLight": #9bf3ab,
  "warningLight": #ffff85,
  "primarySuperDark": #62757f,
  "secondaryLight": #88ffff,
);

$spacer: 1rem;
$spacers: (
  6: (
    $spacer * 6,
  ),
  10: (
    $spacer * 10,
  ),
);
body,
option,
select,
textarea,
input[type="submit"] {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

// prevent deleting \n (new line) when rendering content
.keep-br {
  white-space: pre-wrap;
}

/* .App-header {
  background-color: #62757f;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;
} */
.main-loading {
  margin: 20%;
}

thead,
th.fixed-values-table {
  background-color: #90a4ae;
}
tr.sum-row {
  font-weight: 700;
}

.clickable:hover {
  background-color: $secondarySuperLight;
  cursor: pointer;
}

.center-content {
  text-align: center;

  align-items: center;
  align-content: center;
}

td,
th {
  padding: 0.5rem;
}
td.hidden-cell {
  visibility: hidden;
}
.transaction-details-tr:hover {
  background-color: $primaryLight;
  cursor: pointer;
}

.fixed-values-table {
  background-color: #c1d5e0;
}

.add-transaction-container {
  display: grid;
  grid-template-columns: 5% 1fr 1fr 1fr 5%;
  grid-template-rows: 35px 50px auto 1fr auto auto auto 1fr;
  align-content: center;
  justify-content: center;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}
.grid-content {
  grid-column: main;
  grid-row: main;
  justify-self: center;
}

.pretty-table-pagination {
  justify-self: right;
}

/* FORMS */
label {
  margin-left: 5px;
}
.small-number-input {
  width: 4rem !important;
}
.medium-number-input {
  width: 5rem !important;
}
.large-number-input {
  width: 7rem !important;
}
.customerType-radio {
  padding-left: 5rem;
}
.input-error {
  background-color: $danger !important;
}

label.not-required {
  font-style: italic;
  color: gray;
}


    input.no-arrows::-webkit-outer-spin-button,
    input.no-arrows::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  
    /* Firefox */
    input.no-arrows[type="number"] {
      -moz-appearance: textfield;
    }


/* TRANSACTIONS TABLE */
.transactions-table-container {
  display: grid;
  grid-template-columns: 10% 1fr 15% 10%;
  grid-template-rows: 80px 1fr 1fr 5%;
}

.transactions-table {
  grid-row: 2;
  grid-column: 2 / span 2;
}

.transactions-table-pagination {
  grid-row: 3;
  grid-column: 3;
  justify-self: end;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
}

.transactions-table-pagination-button-prev {
  grid-row: 1;
  grid-column: 1;
  background-color: white;
  padding: 0;
  cursor: pointer;
  color: black;
  width: 2rem;
}
.transactions-table-pagination-button-next {
  grid-row: 1;
  grid-column: 3;
  background-color: white;
  padding: 0;
  cursor: pointer;
  color: black;
  width: 2rem;
}

.transactions-table-pagination-site {
  grid-row: 1;
  grid-column: 2;
  justify-self: center;
  align-self: center;
  font-size: small;
}

.transactions-table-pagination-select {
  grid-column: 1/3;
  grid-row: 2;
  align-self: start;
  width: auto;
}
/* TRANSACTION DETAILS */
.transaction-details-container {
  display: grid;
  grid-template-columns: 5% 1fr 1fr 1fr 5%;
  grid-template-rows: 80px 1fr 1fr 5%;
}

.transaction-details-table {
  grid-column: 2 / span 3;
  grid-row: 2;
}

.customer-details {
  grid-column: 2;
  grid-row: 3;
}

.realization-details {
  grid-column: 3;
  grid-row: 3;
}

.delivery-details {
  grid-column: 4;
  grid-row: 3;
}
// ANIMATIONS

@keyframes loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  animation-name: loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}
// BOOTSTRAP
$grid-gutter-width: 30px !default;

.dropdown-menu{
  // added when working on DebtCollection to improve dropdown menu layout
  min-width: fit-content !important;
}

.content-container {
  margin: 1rem;
}
.bg-primary {
  background-color: $primaryDark;
}
.bg-primaryDark {
  background-color: $primaryDark;
}
.bg-primaryLight {
  background-color: $primaryLight;
}
.bg-secondarySuperLight {
  background-color: $secondarySuperLight;
}
.bg-warningLight {
  background-color: $warningLight;
}
.nav-link-main {
  background-color: $secondary !important;
  margin-right: 10px;
  margin: 3px;
  color: black !important;
  &:hover {
    background-color: $secondaryLight !important;
  }
}
// .nav-link:hover {
//   background-color: $secondaryLight;
// }
.nav-menu {
  background-color: rgba($color: #c1d5e0, $alpha: 0.5) !important;
  padding: 10px !important;
  border: none !important;
}
.navbar-custom {
  background-color: #ff5500;
}
.nav-link.active {
  background-color: $secondarySuperLight !important;
}

select {
  margin: 0.3rem !important;
}
label {
  margin-right: 5px;
}
// changing table borders color:

table.table-bordered,
table.table-bordered > tbody > tr > td,
table.table-bordered > tfoot > tr > td,
table.table-bordered > thead > tr > th {
  border: 1px solid $primaryDark;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  background-color: $primaryLight;
}

.modal-max-width {
  max-width: none !important;
}

.hide-spinner::-webkit-inner-spin-button,
.hide-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.pac-container {
  background-color: #fff;
  z-index: 1200 !important;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: always;
  }
  .hide-for-print {
    display: none !important ;
  }
  .show-for-print {
    display: block !important ;
  }
 
}

@page {
  size: auto;
  margin: 20mm;


}


table.jobCard {
  border: 1px solid black !important;
  td {
    border: 1px solid black !important;
  }
}

// used in jobCard to prevent thick line on contact of two tables:
table.negMarginBottom {
  margin-bottom: -1px !important;
}

table.compact-table {
  td,
  tr {
    margin: 0;
    padding: 0;
  }
  td {
    padding-left: 0.5rem;
  }
}

// ANIMATIONS

@keyframes red-alert {
  0% {
    background-color: #f6a3a2;
  }
  50% {
    background-color: #ef5350;
  }
  100% {
    background-color: #f6a3a2;
  }
}

@import "~bootstrap/scss/bootstrap";
